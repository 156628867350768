import React, { Component } from "react"
import { HeaderImageGrid } from "../components/listingDetailPage/headerImageGrid"
import { BuildingDetailContent } from "../components/buildingDetailPage/buildingDetailContent"
import { Typography } from "@material-ui/core"

class BuildingDetailPage extends Component {
  render() {
    return <BuildingDetailContent />
  }
}

export default BuildingDetailPage
