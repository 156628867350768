import React from "react"
import { Typography, Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { useTheme } from "@material-ui/core/styles"
import apartment from "../../images/apartmentUnSelected.svg"

export const BuildingDetailBasicInfo = () => {
  const classes = useStyles()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down("sm"))

  const WebActiveCase = () => (
    <Grid container xs={12} sm={4}>
      <div style={{ marginLeft: "auto" }}>
        <img
          style={{
            width: "60px",
            height: "70px",
          }}
          src={apartment}
          alt="icon"
        />
      </div>
    </Grid>
  )

  const MobileActiveCase = () => (
    <div style={{ display: "flex", marginLeft: "auto" }}>
      <img
        style={{ width: "60px", height: "70px" }}
        src={apartment}
        alt="icon"
      />
    </div>
  )

  return (
    <div className={classes.root}>
      <Grid container xs={12}>
        <Grid container xs={12} sm={8}>
          <div>
            <Typography className={classes.header} variant="h1">
              BAYSHORE GARDENS
            </Typography>
            <Typography className={classes.subHeader} gutterBottom>
              1717 Bayshore Drive, Coal harbour, Vancouver
            </Typography>
          </div>
        </Grid>
        {matches ? <MobileActiveCase /> : <WebActiveCase />}
      </Grid>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  header: {
    fontSize: "1.75em",
    fontWeight: theme.typography.fontWeightBold,
    color: theme.text.primary,
  },
  subHeader: {
    fontSize: "18px",
    fontWeight: 400,
    color: theme.text.primary,
  },
  active: {
    fontSize: "24px",
    fontWeight: 700,
    color: theme.text.lightBlue,
  },
  footer: {
    fontSize: "16px",
    color: theme.text.primary,
  },
  listedBy: {
    fontSize: "11px",
    color: theme.text.primary,
  },
}))
