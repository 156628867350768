import React from "react"
import { HeaderImageGrid } from "../listingDetailPage/headerImageGrid"
import { ThemeProvider } from "@material-ui/core/styles"
import theme from "../../components/Theme"
import { makeStyles } from "@material-ui/core/styles"
import logo from "../../images/logo.svg"
import { BuildingDetailBasicInfo } from "./buildingDetailBasicInfo"
import { BuildingDetailStrata } from "./buildingDetailStrata"
import { BuildingDetailAmenities } from "./buildingDetailAmenities"

import { Grid } from "@material-ui/core"

const DrawLine = () => {
  return (
    <Grid container xs={12}>
      <div
        style={{
          height: "1px",
          backgroundColor: "#9b9b9b",
          width: "100%",
          marginTop: "1em",
          marginBottom: "1em",
        }}
      ></div>
    </Grid>
  )
}

export const BuildingDetailContent = () => {
  const classes = useStyles()

  return (
    <ThemeProvider theme={theme}>
      <div style={{ display: "block" }}>
        <HeaderImageGrid />
        <Grid style={{ padding: "4%" }} container xs={12}>
          <Grid container style={{ paddingRight: "2%" }} sm={12} md={8}>
            {/* Basic information */}
            <BuildingDetailBasicInfo />
            <DrawLine />
            <BuildingDetailStrata />
            <DrawLine />
            <BuildingDetailAmenities />
            <DrawLine />
          </Grid>
          <Grid
            style={{
              justifyContent: "center",
            }}
            container
            xs={12}
            sm={12}
            md={4}
          ></Grid>
        </Grid>
      </div>
    </ThemeProvider>
  )
}

const useStyles = makeStyles(theme => ({
  footer: {
    paddingLeft: "4%",
    paddingRight: "4%",
    display: "block",
    marginTop: "1em",
  },
  footerText: {
    fontSize: "12px",
    color: "#333",
    textAlign: "center",
  },
  logo: {
    position: "relative",
    height: "2.2em",
    padding: "1em",
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
  },
}))
